<template>
  <v-layout justify-center ma-10>
    <v-card
      outlined
      height="40%"
      align="center"
      v-for="video in videos"
      :key="video.title"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="headline mb-1">{{ video.title }}</v-list-item-title>
          <v-divider inset></v-divider>
          <vue-player
            :src="video.source"
            :poster="video.poster"
            />
          <v-list-item-subtitle class="headline mb-1 text-wrap">
            {{ video.copyright }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-layout>
</template>

<script>
import vuePlayer from '@algoz098/vue-player'

export default {
  name: 'ShowVideo',
  components: {
    vuePlayer
  },
  data () {
    return {
      videos: [
        {
          title: 'Sot - I\'y - laisse',
          source: process.env.VUE_APP_API + '/assets/videos/soly141007.mp4',
          poster: process.env.VUE_APP_API + '/assets/videos/soly141007.png',
          copyright: '© Michele Tonteling, Anna Krieps'
        },
      ]
    }
  }
}
</script>
